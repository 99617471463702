@import '../../mixins';

.catalog {
  & .catalog {
    &-card {
      --section-bg-card: #f4f3f1;
      width: 100%;
    }
  }

  &-page__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: rem(20);
    row-gap: rem(20);
  
    @include mediaBigDesktop {
      column-gap: big(20);
      row-gap: big(20);
    }
  
    @include media(#{rem(1300)}) {
      grid-template-columns: repeat(auto-fill,minmax(rem(167),1fr));
    }
  
    @include media(#{rem(570)}) {
      grid-template-columns: repeat(auto-fill,minmax(rem(135),1fr));
    }
  }
}
